import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {UserContext} from '../login/login_page';
import {db} from "../firebase/firebase_app"

function QuestionListener(sessionId, callback) {
  try {
    var rootRef = db.ref();
    var userList = rootRef.child("session_id").child(sessionId).child("users");
    userList.on('value', (snapshot) => {
      callback({snapShotData:true, SnapShotVal: snapshot.val()});
  });
  }catch (error) {
    console.error("Error At Session User Listener .." , error);
    callback({snapShotData:false});
  }
}

function QuesitonCard(props) {

  const questionData = props.value
  const [view, setView] = React.useState('index');

  const updateView = (event, nextView) => {
    setView(nextView);
    event.preventDefault();
  };
  
  const submitAnswer = (event, nextView) => {
    props.onTrigger(view);
    event.preventDefault();
  };
  
  console.log("Selected Prop's Answer :  ", questionData)
  const listItems = questionData.answers.map((answer, index) =>
          <ToggleButton  key={answer.toString()} value={index}>
              {answer.toString()}
          </ToggleButton>
  );

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {props.question.toString()}
        </Typography>
      </CardContent>

      <CardContent>
          <ToggleButtonGroup orientation="vertical" value={view} exclusive onChange={updateView}>
              {listItems}
          </ToggleButtonGroup>
      </CardContent>

      <CardActions>
        <Button onClick={submitAnswer}>Submit Answer</Button>
      </CardActions>
          
    </Card>
  );
}



//Todo Should be able to handle two different options
// Multiple Anwers vs Only One Answer vs Ranking


// Lets see if we can read from the context


class QuestionBlock extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data: this.props.questionData,
            selectAnswer : this.props.selectAnswered
        }
    }
    
    handleSubmit = (answer) => {
        console.log("Sending answer to Parent")
        this.setState({selectAnswer: answer})
        this.props.submitAnswer(answer);
        console.log("Submitting Answer to parent" , answer)
        // this.props.answerQuestion(this.props.view);
    }
    render(){
        const {data, selectAnswered} = this.state; 
        console.log("This is Called at question wehere " , UserContext.Provider.value)
        return(
            <div>
              <QuesitonCard question={data.question} value={data} onTrigger={this.handleSubmit} selectAnswer={selectAnswered}/>
            </div>
        )
    }
}

export default QuestionBlock;

