export const config = {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    apiKey: "AIzaSyBbSzhZMUeOD0NiqjKjHIq7aTor255CY20",
    authDomain: "onlyme-18690.firebaseapp.com",
    databaseURL: "https://onlyme-18690-default-rtdb.firebaseio.com",
    projectId: "onlyme-18690",
    storageBucket: "onlyme-18690.appspot.com",
    messagingSenderId: "630941345857",
    appId: "1:630941345857:web:63f31ac4c4929823723ec0",
    measurementId: "G-BNFBZB8NE3"
  }
  