import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import GetQuestion from './components/question'
import GameSession from './components/sessions/sessions'
import UserLogin from './components/login/login_page'
import QuestionCard from './components/question_components/question'

ReactDOM.render(
  <React.StrictMode>
    <div class="main-grid">
      <nav class="main-nav">
      <a class="menu-item"><span class="icon">🔔</span></a>
      <br/>
      <a class="menu-item"><span class="icon">🤯</span></a>
      </nav>
    <main class="main-content">
      <UserLogin/>
    </main>
    <aside class="main-side">
      <span>
      Chocolate Sea - Is The Best!
      </span>
      <br/>
      <span>
      Live Sessions with 100 People!
      </span>
    </aside>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
