import React, {
  useCallback, 
  useEffect, 
  useState
} from 'react';
import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import firebase from "firebase/app";
import "firebase/auth";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import GameSession from '../sessions/sessions'


import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';

import {db} from "../firebase/firebase_app"
// ********************** Set User Session React Context **************************
// Setting up Basic Context
export const UserContext = React.createContext({});


// **************** Functions for Authentications *******************


function onAuthStateChange(callback) {
  // console.log("Checking auth states", callback)
  return firebase.auth().onAuthStateChanged(user => {
    if (user) {
      UserContext.Provider.value = {uid : user.uid, sessionId : "No-Session"}
      callback({loggedIn: true, email: user.email, uid:user.uid});
    } else {
      callback({loggedIn: false});
    }
  });
}

async function getUserName(userUid, callback) {
  if (userUid){
    // Update Session
    var rootRef = db.ref();
    try {
      const snapshot = await rootRef.child("users").child(userUid).child("username").get();
      if (snapshot.exists) {
        callback(snapshot.val())
        UserContext.Provider.value.username = snapshot.val()
      } else {
        callback("")
        UserContext.Provider.value.username = ""
      }
    } catch (error) {
      console.log("ERRROR SOME ERROR" , error)
      console.error(error);
    }
  }
}

async function createUserName(userUid, callback) {
  if (userUid){
    // Update Session
    var rootRef = db.ref();
    try {
      const snapshot = await rootRef.child("users").child(userUid).child("username").get();
      if (snapshot.exists) {
        callback(snapshot.val())
        UserContext.Provider.value.username = snapshot.val()
      } else {
        callback("")
        UserContext.Provider.value.username = ""
      }
    } catch (error) {
      console.log("ERRROR SOME ERROR" , error)
      console.error(error);
    }
  }
}


function login(username, password) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(username, password)
      .then(() => resolve())
      .catch(error => reject(error));
  });
}


function signUpWithEmailPassword(email, password, username) {
  // [START auth_signup_password]
  firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      console.log("Sign in ....", userCredential)
      // Signed in 
      // var rootRef = db.ref();
      login(email,password)
      var rootRef = db.ref();
      var dict = {};
      dict['username'] = username
      rootRef.child("users").child(userCredential.user.uid).update(dict)
      // ...
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      // ..
    });
}

function logout() {
  firebase.auth().signOut();
}

// ******************** Login/Logout View Start  ************************
const fromStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '100%',
  },
  displayBlock: {
    display: 'block',
  }
}));

const avatarStyles = makeStyles((theme) => ({
  sizeAvatar: {
    fontSize: '80px',
    width: '120px',
    height: '120px',
  },
  smallAvatar: {
    fontSize: '40px',
    width: '60px',
    height: '60px',
  },
}));

const containerDiv = makeStyles((theme) => ({
  centerDiv: {
    display:'flex', 
    justifyContent:'center' 
  },
}));

function LoginView({ onClick, error }) {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    username: '',
    createAccount: false,
    showPassword: false,
  });

  const classes = fromStyles();
  const avatarClass = avatarStyles()
  
  const standardDiv = containerDiv();
  // Create a function that gets called when button is clicked
  // We want to get the username and password and submit it.
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickCreateAccount = () => {
    setValues({ ...values, createAccount: true });
    console.log("HAndle Click Create Account")
    signUpWithEmailPassword(values.email, values.password, values.username)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  if (values.createAccount) {
    console.log("Create an Account...")
    return (
    
      <div className={standardDiv.centerDiv}>
      <form className={classes.root} noValidate autoComplete="off">
          <div>
            <div style={{ display:'flex', justifyContent:'center', margin:"30px"}}>
            <Avatar aria-label="recipe" className={avatarClass.sizeAvatar}>
            💡
                </Avatar>  
            </div>
            <span>
              <TextField
                label="email"
                id="standard-start-adornment"
                className={clsx(classes.margin, classes.textField, classes.displayBlock)}
                onChange={handleChange('email')}
              />
            </span>

            <span>
              <TextField
                label="username"
                id="standard-start-adornment"
                className={clsx(classes.margin, classes.textField, classes.displayBlock)}
                onChange={handleChange('username')}
              />
            </span>

            <span>
              <FormControl className={clsx(classes.margin, classes.textField , classes.displayBlock)}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </span>
  
            <Button
              variant="contained"
              color="primary"
              className={clsx( classes.textField , classes.displayBlock)}
              onClick={() => {
                handleClickCreateAccount(values.email, values.password)
              }}
            >
              Submit
            </Button>
                <div>
  
                </div>
  
            <div>
            {error}
          </div>
          </div>
      </form>
      </div>
  
    );
  } else {
    return (
    
      <div className={standardDiv.centerDiv}>
      <form className={classes.root} noValidate autoComplete="off">
          <div>
            <div style={{ display:'flex', justifyContent:'center', margin:"30px"}}>
            <Avatar aria-label="recipe" className={avatarClass.sizeAvatar}>
            💡
                </Avatar>  
            </div>
            <span>
              <TextField
                label="email"
                id="standard-start-adornment"
                className={clsx(classes.margin, classes.textField, classes.displayBlock)}
                onChange={handleChange('email')}
              />
            </span>
            <span>
              <FormControl className={clsx(classes.margin, classes.textField , classes.displayBlock)}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </span>
  
            <Button
              variant="contained"
              color="primary"
              className={clsx( classes.textField , classes.displayBlock)}
              onClick={() => {
                onClick(values.email, values.password);
              }}
            >
              Login Button Here
            </Button>
                <div>
  
                </div>
            <Button
              variant="contained"
              color="primary"
              className={clsx( classes.textField , classes.displayBlock)}
              onClick={() => {
                handleClickCreateAccount()
              }}
            >
              Create a new Account
            </Button>
  
            <div>
            {error}
          </div>
          </div>
      </form>
      </div>
  
    );
  }
  
}


function CreateAccountView({ onClick, error }) {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    username: '',
    showPassword: false,
  });

  const classes = fromStyles();
  const avatarClass = avatarStyles()
  
  const standardDiv = containerDiv();
  // Create a function that gets called when button is clicked
  // We want to get the username and password and submit it.
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    
    <div className={standardDiv.centerDiv}>
    <form className={classes.root} noValidate autoComplete="off">
        <div>
          <div style={{ display:'flex', justifyContent:'center', margin:"30px"}}>
          <Avatar aria-label="recipe" className={avatarClass.sizeAvatar}>
          💡
              </Avatar>  
          </div>
          <span>
            <TextField
              label="email"
              id="standard-start-adornment"
              className={clsx(classes.margin, classes.textField, classes.displayBlock)}
              onChange={handleChange('email')}
            />
          </span>
          <span>
            <FormControl className={clsx(classes.margin, classes.textField , classes.displayBlock)}>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                id="password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </span>

          <Button
            variant="contained"
            color="primary"
            className={clsx( classes.textField , classes.displayBlock)}
            onClick={() => {
              onClick(values.email, values.password);
            }}
          >
            Login Button Here
          </Button>
              <div>

              </div>
          <Button
            variant="contained"
            color="primary"
            className={clsx( classes.textField , classes.displayBlock)}
            onClick={() => {
              console.log("Create a new account")
            }}
          >
            Create a new Account
          </Button>

          <div>
          {error}
        </div>
        </div>
    </form>
    </div>

  );
}


function LogoutView({ onClick, user } ) {
  const [username, setUsername] = useState("");

  const standardDiv = containerDiv();
  const classes = fromStyles();
  const avatarClass = avatarStyles()
  console.log("logout view is  called with " , user)
  // setUsername(UserContext.Provider.value.username )
  useEffect(() => {
    // SessionUserListener(sessionName.sessionName, setUsers);
    console.log("User Data while logged in is : ", user)
    getUserName(user.uid, setUsername);
    console.log("username is " , UserContext.Provider.value)
  }, [user]);

  return (

    <div className={standardDiv.centerDiv}>
      <div style={{ display: 'flex', justifyContent: 'center', margin: "30px" }}>
        <Avatar aria-label="recipe" className={avatarClass.smallAvatar}>
          💡
            </Avatar>
      </div>
      <form className={classes.root} noValidate autoComplete="off">
        <div>
          <div style={{ display:'flex', justifyContent:'center', margin:"30px"}}>
            {username}
          </div>
          <Button
        variant="contained"
        color="primary"
        className={clsx(classes.textField, classes.displayBlock)}
        onClick={() => {
          onClick();
        }}
      >
        Logout
      </Button>
        </div>
    </form>

    </div>


  );
}


// ******************** Login/Logout View End ************************

function UserLogin() {
  const [user, setUser] = useState( {loggedIn: false} );
  const [error, setError] = useState("");
  const [createUserView, setNewUserView] = useState("");
  console.log("user login is called...", user, " : ", error)

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser);   
    return () => {
      unsubscribe();
    }
  }, []);

  const requestLogin = useCallback((username, password) => {
    login(username, password).catch(error => setError(error.code))
  });
  
  const requestLogout = useCallback(() => {
    logout();
  }, []);

  const userInfo = user

  if (!user.loggedIn) {
    console.log("WHAT ABOUT THIS CALLED!@#@!#")
    return (
      <div>
        <Card style={{margin:'5%'}}>
          <LoginView onClick={requestLogin} error={error}/>

        </Card>

      </div>
    )
  }
  else {
    console.log("CALLED!!@!@!@!@!!@!@!@!@!@!@")
    return (
        <div>
          <Card style={{margin:'5%'}}>
            <LogoutView onClick={requestLogout} user={userInfo}/>
          </Card>

            <GameSession/>


          {/*Questions Component here*/}
        </div>
        
        
    )
  }
}




// Eventaully Get User Id
// ********************** Class User Page **************************


export default UserLogin;