import React, {
    useCallback, 
    useEffect, 
    useState
  } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {UserContext} from '../login/login_page';
import {db} from "../firebase/firebase_app"

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// Read a Question from our current Question???

async function GetQuestion(joinSessionId, callback){
    // If a User is logged In then we allow user to Join a Session

  if (UserContext.Provider.value && joinSessionId != ""){
    var rootRef = db.ref();
    console.log("This is called...")

    const snapshot = await rootRef.child("session_id").child(joinSessionId).child("current_question").get();
    if (snapshot.exists()){
      callback(snapshot.val())
    }
    else{
      callback()
    }
  }
};



async function SendAnswer(joinSessionId, answer){
    // If a User is logged In then we allow user to Join a Session
  const uid = UserContext.Provider.value.uid
  if (UserContext.Provider.value && joinSessionId != ""){
    var rootRef = db.ref();

    try{
        var dict = {};
        dict[uid] = true
      rootRef.child("session_id").child(joinSessionId).child("users").child(uid).update({"answered":true})
      rootRef.child("session_id").child(joinSessionId).child("answers").child(answer).update(dict)

    }catch(error){
      console.log("ERROR IS CALLED HWY!!!!", error)
    }
  }
};

async function CheckUserAnswered(sessionId, callback){
    // If a User is logged In then we allow user to Join a Session
    console.log("IS THIS CALED SHWY WHY WYW ")
  const uid = UserContext.Provider.value.uid
  if (UserContext.Provider.value && sessionId != ""){
    var rootRef = db.ref();

    try{
        var dict = {};
        dict[uid] = true
      const snapshot = await rootRef.child("session_id").child(sessionId).child("users").child(uid).child("answered").get()
      if (snapshot.exists()) {
        console.log("WHAT IS THE USERS ANSWRED STATUS" , !snapshot.val())
        callback(!snapshot.val());
      } else {
        rootRef.child("session_id").child(sessionId).child("users").child(uid).update({"answered":false})
        callback(true);
      }
    }catch(error){
      console.log("CHECK ANSWER....ERROR IS CALLED HWY!!!!", error)
    }
  }
};

function RealtimeQuestionListener(sessionId, callback) {
    try {
      var rootRef = db.ref();
      var userList = rootRef.child("session_id").child(sessionId).child("current_question");
      userList.on('value', (snapshot) => {
        callback(snapshot.val());
    });
    }catch (error) {
      console.error("Error At Session User Listener .." , error);
      callback({snapShotData:false});
    }
  }
//Get Quesiton..
// Answer and wait for new question...
const spinnerStyle = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));
  
function GetQuestions({sessionName}) {
    const [currentQuestion, setQuestion] = React.useState('index');
    const [view, setView] = React.useState('');
    const [answer, setAnswer] = React.useState('');
    const [elgible, setEligable] = React.useState(false)
    // const [can, setAnswer] = React.useState('');
    console.log("Session Name ", sessionName, view)

    const classes = spinnerStyle();
    const updateView = (event, view) => {
        setView(view);
        event.preventDefault();
    };

    const SubmitAnswer = useCallback(() => {
        setAnswer(view)
        SendAnswer(sessionName, view)
    });

    const RequestCurrentQuestion = useCallback(() => {
        RealtimeQuestionListener(sessionName, setQuestion)
    });

    const RequestUserAnswered = useCallback(() => {
        CheckUserAnswered(sessionName, setEligable)
    });

    useEffect(() => {
        // RequestSessionEntry(sessionName)
        RequestUserAnswered()
        RequestCurrentQuestion()
    }, [sessionName]);

    

    if (currentQuestion !== "index") {
        var arr = [];


        Object.keys(currentQuestion.answers).forEach(function (key) {
            arr.push(key);
        });

        console.log("Current qeustion is " , currentQuestion)
        if (answer == "" && elgible){
            const listItems = arr.map((answer, index) =>
            <ToggleButton key={answer.toString()} value={answer}>
                {answer.toString()}
            </ToggleButton>
        );
            return (
                <Card style={{margin:'5%'}}>
    
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            {currentQuestion.question.toString()}
                        </Typography>
                    </CardContent>
    
                    <CardContent>
    
                        <ToggleButtonGroup orientation="vertical" value={view} exclusive onChange={updateView}>
                            {listItems}
                        </ToggleButtonGroup>
                    </CardContent>
    
                    <CardActions>
                        <Button onClick={SubmitAnswer}>Submit Answer</Button>
                    </CardActions>
                </Card>
            );
        }
        else {
            const listItems = arr.map((answer, index) =>
                <ToggleButton key={answer.toString()} value={answer} disabled={true}>
                    {answer.toString()}
                </ToggleButton>
            );

            return (
                <div>
                    <Card style={{ margin: '5%' }}>

                        <CardContent>
                            <Typography variant="h5" component="h2">
                                {currentQuestion.question.toString()}
                            </Typography>
                        </CardContent>

                        <CardContent>
                            <ToggleButtonGroup orientation="vertical" value={view} exclusive onChange={updateView}>
                                {listItems}
                            </ToggleButtonGroup>
                        </CardContent>
                        <CardContent>
                        <CircularProgress />

                            Waiting for the x more players...or till timer ends
                        </CardContent>
                        


                    </Card>

                    <Card style={{ margin: '5%' }}>
                        Current Game STats maybe call an api here or something

                    </Card>
                </div>

            )
        }

    }
    else if (currentQuestion !== "index") {
        console.log("Called...")
        return (
            <div>
            <Card style={{margin:'5%'}}>
                STIFF

            </Card>
            <Card style={{margin:'5%'}}>
                STIFF

            </Card>
            </div>

        )
    }
    else {
        return (
            <Card>
                Loading your Questions ...
            </Card>
        );
    }

}

  export default GetQuestions;