// Question Component that Request Question from the Server
// Parses and creates question format

import React from 'react';
import * as myConstClass from './constants';
import './quiz_block.css';
import QuestionBlock from "./question_components/question_block"

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

class GetQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      answered: null,
      items: {}
    };
    this.submitAnswer = this.submitAnswer.bind(this);
  }


  // TODO:
  // Eventually we will know our user_id and know what questions we answered or not
  componentDidMount() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        'question_id': '7777',
        'user_id' : 'andrewlee240'
      })
  };
    fetch(myConstClass.local_url + "/GetQuestion", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          console.log("Loading Question from request localtion", myConstClass.local_url);
          // Change States to know that we are loaded and save results into items
          this.setState({
            isLoaded: true,
            items: result
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  componentDidUpdate(prevState, prevProps) {
    // Typical usage (don't forget to compare props):
    console.log("ComponentDidUpdate")
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        'question_id': '0',
        'user_id' : 'andrewlee240'
      })
  };
    console.log("This state answered" , this.state.answered);
    console.log("This vs Prev state answered" , prevProps.answered , " VS " , this.state.answered);
    // If not ansered do nothing
    if (prevProps.answered !== this.state.answered) {
      if (this.state.answered != null){
        if (this.state)
        this.setState({
          isLoaded: false,
        });
        fetch(myConstClass.local_url + "/GetQuestion", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            console.log("Loading Question from request localtion", myConstClass.local_url);
            // Change States to know that we are loaded and save results into items
            this.setState({
              isLoaded: true,
              items: result
            });

          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        )
      };
    };

    
  }
  
  submitAnswer = (answer) =>{
    console.log("Submited Answer" , answer)
    this.setState({answered: answer})
  }

  // Rendering our Question Block by taking in data from our api
  render() {
    const { error, isLoaded, items, answer} = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Loading
          </Typography>
        </CardContent>
      </Card>
      );

    } else if (isLoaded) {
      return (
        <div>
          <QuestionBlock questionData = {items}  submitAnswer = {this.submitAnswer} selectAnswered={answer} />
        </div>
      );
    }
  }
}


export default GetQuestion;