// Question Component that Request Question from the Server
// Parses and creates question format

import React, {
  useCallback, 
  useEffect, 
  useState
} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button, TextField } from "@material-ui/core";
import {db} from "../firebase/firebase_app"
import {UserContext} from '../login/login_page';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import AvatarGroup from '@material-ui/lab/AvatarGroup';
import GetQuestions from '../question_components/get_question';

const fromStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
}));

const containerDiv = makeStyles((theme) => ({
  centerDiv: {
    display:'flex', 
    justifyContent:'center' 
  },
}));


async function TryJoinSession(joinSessionId, callback, adminCallback){
    // If a User is logged In then we allow user to Join a Session
  console.log("Tring to join sesison id" , joinSessionId)
  const uid = UserContext.Provider.value.uid
  if (UserContext.Provider.value && joinSessionId !== ""){
    var rootRef = db.ref();
    var dict = {};
    dict[uid] = {
      "username" : UserContext.Provider.value.username,
      "inGame" : true
    }

    // See if the room exists, if it doesn't we become admin..
    try{
      const checkSession = await rootRef.child("session_id").child(joinSessionId).get()
      // If Session Exists...
      if (checkSession.exists()){
        rootRef.child("session_id").child(joinSessionId).child("users").child(uid).update(
          {"username": UserContext.Provider.value.username}
        )
        rootRef.child("session_id").child(joinSessionId).child("users").child(uid).update(
          {"inGame": true}
        )
        UserContext.Provider.value.sessionId = joinSessionId
  
        const snapshot = await rootRef.child("session_id").child(joinSessionId).child("users").get();
        if (!snapshot.exists()){
          rootRef.child("session_id").child(joinSessionId).update({session_admin:uid})
          UserContext.Provider.value.admin = true
        }

        const checkAdmin = await rootRef.child("session_id").child(joinSessionId).child("session_admin").get();
        if (checkAdmin.exists()){
          if (checkAdmin.val() === uid){
            rootRef.child("session_id").child(joinSessionId).update({session_admin:uid})
            UserContext.Provider.value.admin = true
            adminCallback(true)
          }
          else {
            adminCallback(false)
          }
          
        }

        callback("")
      }
      // If session doesn't exist we do nothing...
      else{
        console.log("Doesn't exist...")
        callback("Session Doesn't Exist")
      }

    }catch(error){
      console.log("Session Doesn't Exist...", error)
      console.error("ERror is " , error)
    }
  }
};



async function onSessionChanges(sessionId, callback){
  if (UserContext.Provider.value && sessionId !== ""){
    // Update Session 
    var rootRef = db.ref();
    try {
      const snapshot = await rootRef.child("session_id").child(sessionId).child("state").get();
      if (snapshot.exists()) {
        callback(true);
      } else {
        callback(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

}

async function LeaveSession(sessionId) {
  try {
    var rootRef = db.ref();
    const checkSession = await rootRef.child("session_id").child(sessionId).get()
    // If Session Exists...
    if (checkSession.exists()){
      rootRef.child("session_id").child(sessionId).child("users").child(UserContext.Provider.value.uid).update({"inGame": false});
    }
  }catch (error) {
    console.error("Error At Session User Listener .." , error);
  }
}


function RealtimeSessionUserListener(sessionId, callback) {
  try {
    var rootRef = db.ref();
    var userList = rootRef.child("session_id").child(sessionId).child("users");
    userList.on('value', (snapshot) => {
      callback({snapShotData:true, SnapShotVal: snapshot.val()});
  });
  }catch (error) {
    console.error("Error At Session User Listener .." , error);
    callback({snapShotData:false});
  }
}

//Returns view of component when we are in a game session
function InSessionView(sessionName){
  const [users, setUsers] = useState({snapShotData:false});
  // SessionUserListener(sessionName.sessionName, setUsers);
  useEffect(() => {
    // SessionUserListener(sessionName.sessionName, setUsers);
    RealtimeSessionUserListener(sessionName.sessionName, setUsers)

  }, [sessionName.sessionName]);

  if (users.snapShotData){
    var snapShotVals = users.SnapShotVal
    var arr = [];

    if (snapShotVals){
      Object.keys(snapShotVals).forEach(function(key) {
        var dict = {}
        if (snapShotVals[key]["inGame"]){
          dict["id"] = snapShotVals[key].username
          if (snapShotVals[key].answered){
            dict["answered"] = true
          }
          else {
            dict["answered"] = false
          }
          dict["admin"] = snapShotVals[key].admin
          arr.push(dict);  
        }

      });
  
      const listItems = arr.map((user) =>
          
          <Avatar aria-label="recipe">
            {user.id.substring(0,2)}
          </Avatar>
      );
      return (
        <div>
          <div style={{ display:'flex', justifyContent:'center'}}>
            {sessionName.sessionName}
          </div>
          <div>
            <AvatarGroup max={8}>
              {listItems}
            </AvatarGroup>
          </div>
        </div>
      )   
    } else {
      return (
        <div>
          No Other Users in Lobby
        </div>
      )   
    }

  }  
  else {
    return (
      <div>
        Damn this dind't work , Try Refreshing the page.
      </div>
    )
  }

}


function SessionJoinView({ onClick, error }) {
  const [values, setValues] = React.useState({
    sessionId: 'apple-analyst-frame',
  });

  const classes = fromStyles();
  
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  if (values.sessionId !== false){
    return (
      <Card style={{margin:'5%'}}>
      <form className={classes.root} noValidate autoComplete="off">
         <div className={classes.root}>
        <div>
        <TextField
              id="sessionName" 
              label="Session Name" 
              defaultValue={values.sessionId}
              onChange={handleChange('sessionId')}
              onKeyDown={(button) => {
                console.log("BITTON PRESSED DONE " , button)
                if (button.key === 'Enter'){
                  onClick(values.sessionId);
                }
                
              }}
          />
        </div>
  
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => {
                  onClick(values.sessionId);
                }}
        >
          Login
        </Button>
        </div>
        <div>
          {error}
        </div>
      </form>
      </Card>
    );
  }
  else {
    console.log("CALLED------------------")
    return (
      <div> Loading ... </div>
    )
  }

}

function JoinGameSession(){
  // Create SessionName with Session Password
  // Change Session by using an session listener.
  const [sessionName, setSessionName] = useState("");
  const [inSession, setInSession] = useState(false);
  const [sessionExist, setSession] = useState(false);
  const [admin, setAdmin] = useState(false);
  // const RequestSessionEntry = useCallback((sessionId) => {
  //   TryJoinSession(sessionId)
  // });
 
  const RequestSessionExit = () => {
    setInSession(false)
    LeaveSession(sessionName)
    setSessionName("")
  };
    
  const HandleSessionLogin = (enterSessionName) => {
    console.log("change in session id" , enterSessionName)
    // Clean Session Name
    enterSessionName.replace(/[^a-zA-Z-]/g, "")
    setSessionName(enterSessionName.replace(/[^a-zA-Z-]/g, ""));
  };

  const handleUnload = (e) => {
    console.log("Handle UNLOAD STUFF ----------", e)
    LeaveSession(sessionName)
  };

  useEffect(() => {
    // RequestSessionEntry(sessionName)
    onSessionChanges(sessionName, setInSession);
    TryJoinSession(sessionName, setSession, setAdmin)
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [sessionName]);




  if (!inSession){
    console.log("IS admin???? : " , admin)
    return (
      <div>
      <SessionJoinView onClick={HandleSessionLogin} error={sessionExist}/>
      </div>
    )
  }

  else if (inSession && admin) {
    return (
      <div>
        <Card style={{margin:'5%'}}>
          <div>
        <InSessionView sessionName={sessionName} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("Clciks")
                RequestSessionExit()
              }}
            >
              Leave Session
          </Button>

          <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log("Clciks")
                
              }}
            >
              Next Question
          </Button>

          </div>
        </Card>
          <GetQuestions sessionName={sessionName}/>
      </div>
    )
  }
  else {
    
      return (
        <div>
          <Card style={{margin:'5%'}}>
            <div>
          <InSessionView sessionName={sessionName} />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log("Clciks")
                  RequestSessionExit()
                }}
              >
                Leave Session
            </Button>
            
            </div>
          </Card>
            <GetQuestions sessionName={sessionName}/>
        </div>
      )
  }
}


class GameSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      joinedSession: false,
      answered: null,
      items: {}
    };
    // this.submitAnswer = this.submitAnswer.bind(this);
  }


  render() {
    const { error, isLoaded, items, answer} = this.state;
    if (error) {
      console.log("Called 1")
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      console.log("Called 3")
      return (

          <JoinGameSession/>


      );

    } else if (isLoaded) {
      console.log("Called 2")
      return (
        <div>

          <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Lets Set Up Some Session
                </Typography>
            </CardContent>
          </Card>
        </div>

      );
    }
  }
}


export default GameSession;